const BASE_URL = 'https://demo3.e-twi.com/api';
export const API_URL = BASE_URL + '/api';
export const LOGIN_URL = BASE_URL + '/login';
export const LOGOUT_URL = BASE_URL + '/logout';
export const REFRESH_TOKEN_URL = BASE_URL + '/refresh-token';

export function url(path: string): string {
  return API_URL + path;
}

export function isUpdate(obj): boolean {
  return obj.id !== null && obj.id !== undefined && obj.id !== '';
}

export function zeroIfNull(val: number): number {
  return val === undefined || val === null ? 0 : val;
}

export function nullIfUndefined(val: any): any {
  return val === undefined ? null : val;
}

export function emptyArrayIfNull(val: any): any {
  return val === undefined || val === null ? [] : val;
}

export function emptyStringIfNull(val: any): any {
  return val === undefined || val === null ? '' : val;
}
