import {Injectable, numberAttribute} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Area, Department, Plant, PlantOrder, Workstation} from '../model/instruction-list.model';
import {Response} from '../server/response.model';
import {url, zeroIfNull} from '../server/rest.util';
import {
  ADD_AREA,
  ADD_DEPARTMENT,
  ADD_PLANT,
  ADD_WORKSTATION,
  DELETE_PLANT,
  GET_DEPARTMENTS,
  GET_PLANTS,
  GET_PLANTS_NAMES,
  UPDATE_DEPARTMENTS,
  UPDATE_PLANT,
  UPDATE_PLANT_ORDER,
} from '../server/rest-endpoint.constant';
import {NodeTypeRestService} from './node-type-rest.service';
import {NodeType, NodeTypeName} from '../model/node-type.model';
import {ClientRestService} from './client-rest.service';
import {Client} from '../model/client.model';

export interface BackendPlant {
  id: number;
  parentId: number;
  name: string;
  description: string;
  prefix: string;
  orderNumber: number;
  image: string;
  departments: BackendDepartment[];
}

export interface BackendPlantNames {
  id: number;
  name: string;
}

export interface BackendDepartment {
  id?: number;
  parentId: number;
  nodeTypeId: number;
  name: string;
  prefix: string;
  orderNumber: number;
  areas: BackendArea[];
}

export interface BackendArea {
  id: number;
  parentId: number;
  nodeTypeId: number;
  name: string;
  prefix: string;
  workStations: BackendWorkstation[];
  orderNumber: number;
  archived: boolean;
  favorite: boolean;
}

export interface BackendWorkstation {
  id: number;
  name: string;
  parentId: number;
  nodeTypeId: number;

  archived: boolean;
  prefix: string;
  orderNumber: number;
}

export interface PlantRest {
  id?: number;
  parentId: number;
  name: string;
  description: string;
  prefix: string;
  orderNumber: number;
  image: string;
}

@Injectable({providedIn: 'root'})
export class DepartmentRestService {
  constructor(private http: HttpClient, private clientRestService: ClientRestService, private nodeTypeRestService: NodeTypeRestService) {}

  private DEPARTMENT_NODE_TYPE: NodeType;
  private AREA_NODE_TYPE: NodeType;
  private WORKSTATION_NODE_TYPE: NodeType;

  public saveNewDepartment(department: Department): Observable<number> {
    return this.initializeNodes().pipe(
      switchMap(data => this.clientRestService.findActiveClient()),
      switchMap(company =>
        this.http.post<Response<number>>(url(ADD_DEPARTMENT), this.mapDepartmentToBackendDepartment(department, department.orderNumber)),
      ),
      map(response => response.result),
    );
  }

  public saveNewArea(area: Area, departmentId: number): Observable<number> {
    return this.initializeNodes().pipe(
      switchMap(data => this.http.post<Response<number>>(url(ADD_AREA), this.mapAreaToBackendArea(area, departmentId, area.orderNumber))),
      map(response => response.result),
    );
  }

  public saveNewWorkstation(workstation: Workstation, areaId: number): Observable<number> {
    return this.initializeNodes().pipe(
      switchMap(data =>
        this.http.post<Response<number>>(
          url(ADD_WORKSTATION),
          this.mapWorkstationToBackendWorkstation(workstation, areaId, workstation.orderNumber),
        ),
      ),
      map(response => response.result),
    );
  }

  public saveAll(departments: Department[]): Observable<any> {
    return this.initializeNodes().pipe(
      switchMap(data => this.clientRestService.findActiveClient()),
      switchMap(company =>
        this.http.put<Response<boolean>>(
          url(UPDATE_DEPARTMENTS),
          departments.map((dep, order) => this.mapDepartmentToBackendDepartment(dep, order)),
        ),
      ),
    );
  }

  public findAll(): Observable<Department[]> {
    return this.initializeNodes().pipe(
      switchMap(data => this.clientRestService.findActiveClient()),
      switchMap(company => {
        return this.http
          .get<Response<BackendDepartment[]>>(url(GET_DEPARTMENTS), {
            params: new HttpParams().set('plantId', +localStorage.getItem('plantId') + ''),
          })
          .pipe(
            map(response => {
              return response.result.map(dep => this.mapBackendDepartmentToDepartment(dep));
            }),
          );
      }),
    );
  }

  public addPlant(plantRest: PlantRest): Observable<PlantRest> {
    return this.http.post<PlantRest>(url(ADD_PLANT), plantRest);
  }

  public updatePlant(plantRest: PlantRest): Observable<PlantRest> {
    return this.http.put<PlantRest>(url(UPDATE_PLANT), plantRest);
  }

  public updatePlantOrder(plantOrder: PlantOrder[]): Observable<PlantOrder[]> {
    return this.http.put<PlantOrder[]>(url(UPDATE_PLANT_ORDER), plantOrder);
  }

  public deletePlant(plantId: number): Observable<any> {
    return this.http.delete(url(DELETE_PLANT), {
      params: new HttpParams().set('id', plantId + ''),
    });
  }

  public findAllPlants(): Observable<Plant[]> {
    return this.clientRestService.findActiveClient().pipe(
      switchMap(company => {
        return this.http
          .get<BackendPlant[]>(url(GET_PLANTS), {
            params: new HttpParams().set('companyId', company.id + ''),
          })
          .pipe(
            map(response => {
              return response.map(plants => this.mapBackendPlantToPlant(plants));
            }),
          );
      }),
    );
  }
  public findAllPlantsNames(): Observable<BackendPlantNames[]> {
    return this.clientRestService.findActiveClient().pipe(
      switchMap(company => {
        return this.http.get<BackendPlant[]>(url(GET_PLANTS_NAMES), {
          params: new HttpParams().set('companyId', company.id + ''),
        });
      }),
    );
  }

  private mapDepartmentToBackendDepartment(department: Department, order: number): BackendDepartment {
    return {
      id: zeroIfNull(department.id),
      name: department.name,
      parentId: department.parentId,
      nodeTypeId: this.DEPARTMENT_NODE_TYPE.id,
      prefix: department.shortcut,
      orderNumber: order,
      areas: department.areas?.map((area, order) => this.mapAreaToBackendArea(area, department.id, order)),
    };
  }

  private mapAreaToBackendArea(area: Area, departmentId: number, order: number): BackendArea {
    return {
      id: zeroIfNull(area.id),
      parentId: departmentId,
      nodeTypeId: this.AREA_NODE_TYPE.id,
      name: area.name,
      prefix: area.shortcut,

      archived: !!area.archived,
      favorite: !!area.favorite,
      orderNumber: order,
      workStations: area.workstations?.map((workstation, order) => this.mapWorkstationToBackendWorkstation(workstation, area.id, order)),
    };
  }

  private mapWorkstationToBackendWorkstation(workstation: Workstation, areaId: number, order: number): BackendWorkstation {
    return {
      id: zeroIfNull(workstation.id),
      parentId: areaId,
      nodeTypeId: this.WORKSTATION_NODE_TYPE.id,
      name: workstation.name,
      prefix: workstation.shortcut,
      archived: !!workstation.archived,
      orderNumber: order,
    };
  }

  private mapBackendPlantToPlant(plant: BackendPlant): Plant {
    return {
      id: plant.id,
      name: plant.name,
      prefix: plant.prefix,
      description: plant.description,
      image: plant.image,
      parentId: plant.parentId,
      orderNumber: plant.orderNumber,
      departments: plant.departments.map(dep => this.mapBackendDepartmentToDepartment(dep)),
    };
  }

  private mapBackendDepartmentToDepartment(department: BackendDepartment): Department {
    return {
      id: department.id,
      name: department.name,
      shortcut: department.prefix,
      orderNumber: department.orderNumber,
      parentId: department.parentId,
      areas: department.areas?.map(area => {
        return {
          id: area.id,
          name: area.name,
          shortcut: area.prefix,
          archived: area.archived,
          favorite: area.favorite,
          orderNumber: area.orderNumber,
          workstations: area.workStations?.map(workstation => {
            return {
              id: workstation.id,
              name: workstation.name,
              shortcut: workstation.prefix,
              archived: workstation.archived,
              favorite: false, // TODO favorite needs to be restructured, I'm leaving it for now to not break logic
              orderNumber: workstation.orderNumber,
            };
          }),
        };
      }),
    };
  }

  private initializeNodes(): Observable<any> {
    return this.nodeTypeRestService.findAll().pipe(
      map(nodeTypes => {
        nodeTypes.forEach(nodeType => {
          switch (nodeType.name) {
            case NodeTypeName.DEPARTMENT:
              this.DEPARTMENT_NODE_TYPE = nodeType;
              break;
            case NodeTypeName.AREA:
              this.AREA_NODE_TYPE = nodeType;
              break;
            case NodeTypeName.WORKSTATION:
              this.WORKSTATION_NODE_TYPE = nodeType;
              break;
          }
        });
        return nodeTypes;
      }),
    );
  }
}
