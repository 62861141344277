import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {url} from '../server/rest.util';
import {GET_WIEN_TEMPLATE, SYSTEM_VERSION} from '../server/rest-endpoint.constant';
import {map} from 'rxjs/operators';
import {Response} from '../server/response.model';

@Injectable({
  providedIn: 'root',
})
export class SystemVersionRestService {
  constructor(private http: HttpClient) {}

  public getSystemVersion = (): Observable<any> => {
    return this.http.get<Response<any>>(url(SYSTEM_VERSION)).pipe(map(data => data.result.data));
  };

  public getWienTemplate = (): Observable<any> => {
    return this.http.get<any>(url(GET_WIEN_TEMPLATE));
  };
}
